import './App.css';
import Navbar from './components/navbar';
import Home from './components/home.js';
import About from './components/About.js';
function App() {
  
  return (
    <div className="App">
     
    </div>
  );
}

export default App;
